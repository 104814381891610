import React from "react";

export default function Home() {
  return (
    <>
      <h1> Dashboard </h1>
      <p> coming soon...</p>
    </>
  );
}
